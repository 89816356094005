import BannerPropertyImages from "@components/BannerPropertyImages/BannerPropertyImages"
import loadable from "@loadable/component"
import FloatingCTAShell from "@components/FloatingCTAShell/FloatingCTAShell"
import Layout from "@components/Layout/Layout"
import PropertyAreaguide from "@components/PropertyAreaguide/PropertyAreaguide"
import PropertyBreadcrumb from "@components/PropertyBreadcrumb/PropertyBreadcrumb"
import PropertyInfo from "@components/PropertyInfo/PropertyInfo"
import PropertyMortgageCalcNew from "@components/PropertyMortgageCalc/PropertyMortgageCalcNew"
import Animation from "@components/elements/Animation"
import Seo from "@components/seo"
import useTeams from "@hooks/dataHooks/useTeams"
import {
  bookAViewingURL,
  mortgagesURL,
  propertyDetailRentURL,
  propertyDetailSaleURL,
} from "@lib/urls"
import { addRollWhatsapp, formatNumber, getWhatsAppURL, getWhatsBaseURL, gtagReportConversion } from "@lib/utils"
import "@styles/property-details.scss"
import { Link, graphql, navigate } from "gatsby"
import { capitalize, get, isEmpty } from "lodash"
import { PhoneIcon } from "lucide-react"
import React, { createRef, useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import SimilarProperties from "@components/SimilarProperties/SimilarProperties"
import { CurrConvertPrice } from "@components/CurrencyConverter/CurrConvertPrice"
import WhatsAppIconWB from "@components/icons/WhatsAppIconWB"
import useDeviceMedia from "@hooks/useDeviceMedia"
import { UserObjectStoreProvider, SaveItem, useUserObjectState, isAuthenticated } from "@starberry/myaccount-website-utils"
import BannerMenu from "@components/Banner/BannerMenu"
const PropertyLocation = loadable(() => import("@components/PropertyLocation/PropertyLocation"))
const PropertyNegoCard = loadable(() => import("@components/PropertyNegoCard/PropertyNegoCard"))
const MailIcon = loadable(() => import("@components/icons/MailIcon"))
const MobileIcon = loadable(() => import("@components/icons/MobileIcon"))
const WhatsAppIcon = loadable(() => import("@components/icons/WhatsAppIcon"))

const PropertyDetailTemplate = ({ data, location }) => {
  const property = data?.allProperties?.nodes?.length > 0 ? data?.allProperties?.nodes[0] : data?.allPropertiesUnpublished?.nodes[0]
  const area = data?.strapiAreaGuide
  // if (!property) {
  //   return typeof window !== "undefined" && navigate("/404/")
  // }
  const building = property?.building?.length > 0 &&  property?.building[0] || "Property"
  const action = property?.search_type === "sales" ? "for sale" : "for rent"
  const bedrooms = property?.bedroom
  const bedroomsText = Number(bedrooms) > 1 ? "bedrooms" : "bedroom"
  const address = property?.display_address
  const price = formatNumber(property?.price)
  const bedtxt = property?.bedroom === 0 && building !== "Land" ? "with Studio" : property?.bedroom !== 0 ? `with ${bedrooms} ${bedroomsText}` : ""
  const crmid = property?.crm_id
  const bitrix_id = property?.extra?.bitrix_id
  const { phone, email, name } = property?.crm_negotiator_id

  const isSales = property?.search_type === "sales"
  const basePath = isSales ? propertyDetailSaleURL : propertyDetailRentURL
  const propertyURL = `${basePath}${property?.slug}${property?.strapi_id}/`
  const message = `Hello Provident,\n\nI would like to know more about this property:\n\n• Reference: ${crmid}\n• Type: ${capitalize(building)}\n• Price: ${CurrConvertPrice(price?.toLocaleString("en-US"))}\n• Location:  ${address}\n• Link: ${process.env.GATSBY_SITE_URL + propertyURL}\n• ID: ${bitrix_id}\n\nModifying this message will prevent it from being sent to the agent.`
  
  const propertyData = {
    address: property?.display_address,
    description: property?.description,
    bathrooms: property?.bathroom,
    bedrooms: property?.bedroom,
    building: property?.building?.length > 0 &&  property?.building[0],
    image: property?.images?.length > 0 && property?.images[0],
    price: property?.price,
    size: property?.floorarea_min,
    crmID: property?.crm_id,
    slug: property?.slug,
    searchType: property?.search_type,
    search_type: property?.search_type,
    strapiID: property?.strapi_id,
    message: message,
    email: property?.crm_negotiator_id?.email,
    name: property?.crm_negotiator_id?.name,
    publish: property?.publish,
    department: property?.department,
    link_to_employee: property?.link_to_employee,

  }

var team_data = useTeams()
let team_key;
if(team_data){
  if (property?.crm_negotiator_id?.id != undefined) {
      for (let k in team_data) {
          if (property?.crm_negotiator_id?.brn_number == team_data[k].negotiator_id) {
              team_key = k;
              break;
          }
      }
  }
}

const {
  isAboveLargeScreen
} = useDeviceMedia()

useEffect(() => {
  if (typeof window !== "undefined") {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({'product_id': property?.crm_id });
  }
}, [property]);
let nearby_places = [
  {label:"Map", value:""},
  {label:"Schools", value:"school"},
  {label:"Restaurants", value:"restaurant"},
  {label:"Hospitals", value:"hospital"},
]
// const myRef = createRef();
    
const[place_type, setPlaceType] = useState("");
const onChangePlace = (place) =>{
  setPlaceType(place);
  // myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "center" }); 
}
const { services } = useUserObjectState()

useEffect(() => {
  if (isAuthenticated()) {
  // on page load get the users saved objects
      services.getUserobject({ type: "property", state:true })
  }
}, [])
  return (
    <>
      <Layout
        pageData={{
          page_class: "property-detail-page",
          layout: "property-detail",
        }}
        hideNewsletter
      >
        <BannerMenu color="black" />
        <PropertyBreadcrumb property={property} title={`${building} ${action} ${bedtxt} in ${address} at AED ${price} [${crmid}]`}
           description={`Know the details of ${building} ${action} with ${bedrooms} ${bedroomsText} in ${address} at ${price} [${crmid}]. Book a viewing with Provident Real Estate to get assistance in finding the right ${building} for you.`} />
        <Container>
         
          <BannerPropertyImages  
          title={`${building} ${action} ${bedtxt} in ${address} at AED ${price} [${crmid}]`}
           description={`Know the details of ${building} ${action} with ${bedrooms} ${bedroomsText} in ${address} at ${price} [${crmid}]. Book a viewing with Provident Real Estate to get assistance in finding the right ${building} for you.`}
            property={property} openGallery={location?.state?.openGallery} />
          <div className="property-detail-body">
            <Animation className="left-section">
              <PropertyInfo property={property}
              propertyData={propertyData}
              propertyURL={propertyURL}
                message={message}
                email={email}
                team_data={team_data}
                team_key={team_key}
                name={name}
              />
              {isSales && property?.publish && (
                <PropertyMortgageCalcNew
                  propertyPrice={property.price}
                  cta_text={{
                    text: "Click here to get started with your mortgage",
                    cta: {
                      cta_label: "Apply for a mortgage",
                      custom_link: mortgagesURL,
                    },
                  }}
                />
              )}
              {/* <div ref={myRef}></div> */}
              <PropertyLocation
                lat={property.latitude}
                lng={property.longitude}
                place_type={place_type}
                nearby_places={nearby_places}
                onChangePlace={onChangePlace}
              />
              
              <PropertyAreaguide property={property} area={area} />
              <div className="divider" />
              <SimilarProperties propId={property.strapi_id} />
            </Animation>
            {/* {property?.publish && */}
            <Animation
              className="right-section"
              wrapperClassName="right-section-wrap"
            >
              {!isEmpty(property) && isAboveLargeScreen &&
              <PropertyNegoCard
                negoData={property.crm_negotiator_id}
                negoID={property.crm_negotiator_id?.brn_number}
                property={propertyData}
                propertyURL={propertyURL}
                message={message}
                email={email}
                team_data={team_data}
                team_key={team_key}
                name={name}
                link_to_employee={property?.link_to_employee}

              />}
            </Animation>
            {/* } */}
          </div>
        </Container>
        
      </Layout>
      {/* {property?.publish && */}
      <FloatingCTAShell message={message} email={property?.link_to_employee?.email} name={name} className={"detail-prop"}>
        {property?.publish &&
        <Link
          to={bookAViewingURL}
          className="button button-orange"
          state={{
            property: propertyData,
            propertyURL: propertyURL,
            email:property?.crm_negotiator_id?.email,
            crm_negotiator_id:property?.crm_negotiator_id?.id,
            name:property?.crm_negotiator_id?.name
          }}
          onClick={() => {
            localStorage?.setItem("propertyURL", propertyURL)
          }}
        >
          <MailIcon color="white" />
          <span>{property?.publish ? "Email" : "Contact Agent"}</span>
          
        </Link>}
        {phone ? (
          <>
            <a
              href={`tel:${phone}`}
              className="button button-orange"
              onClick={() => gtagReportConversion(`tel:${phone}`)}
            >
              <PhoneIcon />
              <span>Call</span>
              
            </a>
            <a
              href={getWhatsAppURL("971505389860", message, property?.link_to_employee?.email, property?.link_to_employee?.phone, name)}
              className="button button-green"
              target="_blank"
              rel="noreferrer"
              onClick={()=> addRollWhatsapp()}
              >
                <WhatsAppIconWB />
              <span>WhatsApp</span>
              
            </a>
          </>
        ) : (
          <>
            <a
              href={`tel:97143233609`}
              className="button button-orange"
              onClick={() => gtagReportConversion(`tel:97143233609`)}
            >
              <span>Call</span>
              <MobileIcon color="white" />
            </a>
            <a
              href={getWhatsBaseURL("97143233609", message)}
              className="button button-green"
              target="_blank"
              rel="noreferrer"
              onClick={()=> addRollWhatsapp()}
              >
              <span>WhatsApp</span>
              <WhatsAppIconWB />
            </a>
          </>
        )}
         {/* <SaveItem className="button button-primary-light" type="property" pid={property?.crm_id}>
         <span className="property-save icon-save" />
            <span className=" property-save icon-saved" />
            <span className="save-text">Save</span>
            <span className="saved-text">Saved</span>
         </SaveItem> */}
      </FloatingCTAShell>
      {/* } */}
    </>
  )
}

const PropertyDetailTemplateWrap = (props) => {
  return(
    <UserObjectStoreProvider>
    <PropertyDetailTemplate {...props} />
    </UserObjectStoreProvider>
  )
}

export default PropertyDetailTemplateWrap

export const Head = ({ data }) => {
  const property = data?.allProperties?.nodes?.length > 0 ? data?.allProperties?.nodes[0] : data?.allPropertiesUnpublished?.nodes[0]
  const building = property?.building?.length > 0 && property?.building[0] || "Property"
  const action = property?.search_type === "sales" ? "for sale" : "for rent"
  const bedrooms = property?.bedroom
  const bedroomsText = Number(bedrooms) > 1 ? "bedrooms" : "bedroom"
  const bedtxt = property?.bedroom === 0 && building !== "Land" ? "with Studio" : property?.bedroom !== 0 ? `with ${bedrooms} ${bedroomsText}` : ""
  const address = property?.display_address
  const price = formatNumber(property?.price)
  const crmid = property?.crm_id
  const images = property?.ggfx_results
  const findImageBySize = (size) => {
    if (!Array.isArray(images)) {
      // If images is not an array, return null or handle the error accordingly
      return null;
    }
  
    for (let image of images) {
      if (!image?.transforms || image?.transforms?.length === 0) continue;
      for (let transform of image.transforms) {
        if (transform?.transform === size) {
          return transform?.url; // Return the URL if the size matches
        }
      }
    }
    return null; // Return null if no image with the desired size is found
  };

const imageUrl = get(property?.algoliaData, "images.0._696x520");

  // const compressedImg = property?.ggfx_results && property?.ggfx_results?.length > 0 && property?.ggfx_results[0]?.transforms 
  // const compressedImgF = compressedImg && compressedImg?.length > 0 && compressedImg[0]?.url
  return (
    <>
    <link rel="preconnect" href={process.env.GATSBY_GGFX_CDN_HOST} />
    <link rel="preload" as="image" href={get(property?.algoliaData, "images.0._464x312") ? get(property?.algoliaData, "images.0._464x312")?.replace(process.env.GATSBY_GGFX_S3_HOST, process.env.GATSBY_GGFX_CDN_HOST) : ""} />
    <Seo
      title={`${building} ${action} ${bedtxt} in ${address} at AED ${price} [${crmid}]`}
      description={`Know the details of ${building} ${action} with ${bedrooms} ${bedroomsText} in ${address} at ${price} [${crmid}]. Book a viewing with Provident Real Estate to get assistance in finding the right ${building} for you.`}
      img={
        imageUrl ? imageUrl : 
        property?.images?.length > 0 && property?.images[0]?.url
      }
    />
    </>
  )
}

export const query = graphql`
  query ($strapi_id: Int, $area: String) {
    allProperties(filter: { strapi_id: { eq: $strapi_id } }) {
      nodes {
        ...propertyFragment
      }
    }
    allPropertiesUnpublished(filter: { strapi_id: { eq: $strapi_id } }) {
      nodes {
        ...propertiesUnpublishedFragment
      }
    }
    strapiAreaGuide(title: { eq: $area }) {
      ...areaguideCardFragment
    }
  }
`
