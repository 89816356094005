import { useGlobalStore } from "@stores/globalStore"
import { useEffect } from "react"
import useQueryParam from "./useQueryParam"
import useIP from "./useIP"
import { currencyTypeOptionsSymbols } from "@components/SearchResults/SearchFilters/filterOptions"
const countryList = [
  {
      "COUNTRY": "Bahrain",
      "COUNTRY_CODE": "973",
      "Country_ISO": "BH",
      "Currency_ISO": "BHR",
      "Continent": "Asia",
      "Currency": "AED"
  },
  {
      "COUNTRY": "Iraq",
      "COUNTRY_CODE": "964",
      "Country_ISO": "IQ",
      "Currency_ISO": "IRQ",
      "Continent": "Asia",
      "Currency": "AED"
  },
  {
      "COUNTRY": "Kuwait",
      "COUNTRY_CODE": "965",
      "Country_ISO": "KW",
      "Currency_ISO": "KWT",
      "Continent": "Asia",
      "Currency": "AED"
  },
  {
      "COUNTRY": "Oman",
      "COUNTRY_CODE": "968",
      "Country_ISO": "OM",
      "Currency_ISO": "OMN",
      "Continent": "Asia",
      "Currency": "AED"
  },
  {
      "COUNTRY": "Qatar",
      "COUNTRY_CODE": "974",
      "Country_ISO": "QA",
      "Currency_ISO": "QAT",
      "Continent": "Asia",
      "Currency": "AED"
  },
  {
      "COUNTRY": "Saudi Arabia",
      "COUNTRY_CODE": "966",
      "Country_ISO": "SA",
      "Currency_ISO": "SAU",
      "Continent": "Asia",
      "Currency": "AED"
  },
  {
      "COUNTRY": "United Arab Emirates",
      "COUNTRY_CODE": "971",
      "Country_ISO": "AE",
      "Currency_ISO": "ARE",
      "Continent": "Asia",
      "Currency": "AED"
  },
  {
      "COUNTRY": "Albania",
      "COUNTRY_CODE": "355",
      "Country_ISO": "AL",
      "Currency_ISO": "ALB",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Andorra",
      "COUNTRY_CODE": "376",
      "Country_ISO": "AD",
      "Currency_ISO": "AND",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Austria",
      "COUNTRY_CODE": "43",
      "Country_ISO": "AT",
      "Currency_ISO": "AUT",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Belarus",
      "COUNTRY_CODE": "375",
      "Country_ISO": "BY",
      "Currency_ISO": "BLR",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Belgium",
      "COUNTRY_CODE": "32",
      "Country_ISO": "BE",
      "Currency_ISO": "BEL",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Bosnia and Herzegovina",
      "COUNTRY_CODE": "387",
      "Country_ISO": "BA",
      "Currency_ISO": "BIH",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Bulgaria",
      "COUNTRY_CODE": "359",
      "Country_ISO": "BG",
      "Currency_ISO": "BGR",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Croatia",
      "COUNTRY_CODE": "385",
      "Country_ISO": "HR",
      "Currency_ISO": "HRV",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Czech Republic",
      "COUNTRY_CODE": "420",
      "Country_ISO": "CZ",
      "Currency_ISO": "CZE",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Denmark",
      "COUNTRY_CODE": "45",
      "Country_ISO": "DK",
      "Currency_ISO": "DNK",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Estonia",
      "COUNTRY_CODE": "372",
      "Country_ISO": "EE",
      "Currency_ISO": "EST",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Faroe Islands",
      "COUNTRY_CODE": "298",
      "Country_ISO": "FO",
      "Currency_ISO": "FRO",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Finland",
      "COUNTRY_CODE": "358",
      "Country_ISO": "FI",
      "Currency_ISO": "FIN",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "France",
      "COUNTRY_CODE": "33",
      "Country_ISO": "FR",
      "Currency_ISO": "FRA",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Germany",
      "COUNTRY_CODE": "49",
      "Country_ISO": "DE",
      "Currency_ISO": "DEU",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Gibraltar",
      "COUNTRY_CODE": "350",
      "Country_ISO": "GI",
      "Currency_ISO": "GIB",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Greece",
      "COUNTRY_CODE": "30",
      "Country_ISO": "GR",
      "Currency_ISO": "GRC",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Guernsey",
      "COUNTRY_CODE": "44-1481",
      "Country_ISO": "GG",
      "Currency_ISO": "GGY",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Hungary",
      "COUNTRY_CODE": "36",
      "Country_ISO": "HU",
      "Currency_ISO": "HUN",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Iceland",
      "COUNTRY_CODE": "354",
      "Country_ISO": "IS",
      "Currency_ISO": "ISL",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Ireland",
      "COUNTRY_CODE": "353",
      "Country_ISO": "IE",
      "Currency_ISO": "IRL",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Isle of Man",
      "COUNTRY_CODE": "44-1624",
      "Country_ISO": "IM",
      "Currency_ISO": "IMN",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Italy",
      "COUNTRY_CODE": "39",
      "Country_ISO": "IT",
      "Currency_ISO": "ITA",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Jersey",
      "COUNTRY_CODE": "44-1534",
      "Country_ISO": "JE",
      "Currency_ISO": "JEY",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Latvia",
      "COUNTRY_CODE": "371",
      "Country_ISO": "LV",
      "Currency_ISO": "LVA",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Liechtenstein",
      "COUNTRY_CODE": "423",
      "Country_ISO": "LI",
      "Currency_ISO": "LIE",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Lithuania",
      "COUNTRY_CODE": "370",
      "Country_ISO": "LT",
      "Currency_ISO": "LTU",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Luxembourg",
      "COUNTRY_CODE": "352",
      "Country_ISO": "LU",
      "Currency_ISO": "LUX",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Macedonia",
      "COUNTRY_CODE": "389",
      "Country_ISO": "MK",
      "Currency_ISO": "MKD",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Malta",
      "COUNTRY_CODE": "356",
      "Country_ISO": "MT",
      "Currency_ISO": "MLT",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Moldova",
      "COUNTRY_CODE": "373",
      "Country_ISO": "MD",
      "Currency_ISO": "MDA",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Monaco",
      "COUNTRY_CODE": "377",
      "Country_ISO": "MC",
      "Currency_ISO": "MCO",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Montenegro",
      "COUNTRY_CODE": "382",
      "Country_ISO": "ME",
      "Currency_ISO": "MNE",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Netherlands",
      "COUNTRY_CODE": "31",
      "Country_ISO": "NL",
      "Currency_ISO": "NLD",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Norway",
      "COUNTRY_CODE": "47",
      "Country_ISO": "NO",
      "Currency_ISO": "NOR",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Poland",
      "COUNTRY_CODE": "48",
      "Country_ISO": "PL",
      "Currency_ISO": "POL",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Portugal",
      "COUNTRY_CODE": "351",
      "Country_ISO": "PT",
      "Currency_ISO": "PRT",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Romania",
      "COUNTRY_CODE": "40",
      "Country_ISO": "RO",
      "Currency_ISO": "ROU",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "San Marino",
      "COUNTRY_CODE": "378",
      "Country_ISO": "SM",
      "Currency_ISO": "SMR",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Serbia",
      "COUNTRY_CODE": "381",
      "Country_ISO": "RS",
      "Currency_ISO": "SRB",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Slovakia",
      "COUNTRY_CODE": "421",
      "Country_ISO": "SK",
      "Currency_ISO": "SVK",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Slovenia",
      "COUNTRY_CODE": "386",
      "Country_ISO": "SI",
      "Currency_ISO": "SVN",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Spain",
      "COUNTRY_CODE": "34",
      "Country_ISO": "ES",
      "Currency_ISO": "ESP",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Svalbard and Jan Mayen",
      "COUNTRY_CODE": "47",
      "Country_ISO": "SJ",
      "Currency_ISO": "SJM",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Sweden",
      "COUNTRY_CODE": "46",
      "Country_ISO": "SE",
      "Currency_ISO": "SWE",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Switzerland",
      "COUNTRY_CODE": "41",
      "Country_ISO": "CH",
      "Currency_ISO": "CHE",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Ukraine",
      "COUNTRY_CODE": "380",
      "Country_ISO": "UA",
      "Currency_ISO": "UKR",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Vatican",
      "COUNTRY_CODE": "379",
      "Country_ISO": "VA",
      "Currency_ISO": "VAT",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Kosovo",
      "COUNTRY_CODE": "383",
      "Country_ISO": "XK",
      "Currency_ISO": "XKX",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "Netherlands Antilles",
      "COUNTRY_CODE": "599",
      "Country_ISO": "AN",
      "Currency_ISO": "ANT",
      "Continent": "Europe",
      "Currency": "EUR"
  },
  {
      "COUNTRY": "United Kingdom",
      "COUNTRY_CODE": "44",
      "Country_ISO": "GB",
      "Currency_ISO": "GBR",
      "Continent": "Europe",
      "Currency": "GBP"
  },
  {
      "COUNTRY": "Algeria",
      "COUNTRY_CODE": "213",
      "Country_ISO": "DZ",
      "Currency_ISO": "DZA",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Angola",
      "COUNTRY_CODE": "244",
      "Country_ISO": "AO",
      "Currency_ISO": "AGO",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Benin",
      "COUNTRY_CODE": "229",
      "Country_ISO": "BJ",
      "Currency_ISO": "BEN",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Botswana",
      "COUNTRY_CODE": "267",
      "Country_ISO": "BW",
      "Currency_ISO": "BWA",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "British Indian Ocean Territory",
      "COUNTRY_CODE": "246",
      "Country_ISO": "IO",
      "Currency_ISO": "IOT",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Burkina Faso",
      "COUNTRY_CODE": "226",
      "Country_ISO": "BF",
      "Currency_ISO": "BFA",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Burundi",
      "COUNTRY_CODE": "257",
      "Country_ISO": "BI",
      "Currency_ISO": "BDI",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Cameroon",
      "COUNTRY_CODE": "237",
      "Country_ISO": "CM",
      "Currency_ISO": "CMR",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Cape Verde",
      "COUNTRY_CODE": "238",
      "Country_ISO": "CV",
      "Currency_ISO": "CPV",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Central African Republic",
      "COUNTRY_CODE": "236",
      "Country_ISO": "CF",
      "Currency_ISO": "CAF",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Chad",
      "COUNTRY_CODE": "235",
      "Country_ISO": "TD",
      "Currency_ISO": "TCD",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Comoros",
      "COUNTRY_CODE": "269",
      "Country_ISO": "KM",
      "Currency_ISO": "COM",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Democratic Republic of the Congo",
      "COUNTRY_CODE": "243",
      "Country_ISO": "CD",
      "Currency_ISO": "COD",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Djibouti",
      "COUNTRY_CODE": "253",
      "Country_ISO": "DJ",
      "Currency_ISO": "DJI",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Egypt",
      "COUNTRY_CODE": "20",
      "Country_ISO": "EG",
      "Currency_ISO": "EGY",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Equatorial Guinea",
      "COUNTRY_CODE": "240",
      "Country_ISO": "GQ",
      "Currency_ISO": "GNQ",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Eritrea",
      "COUNTRY_CODE": "291",
      "Country_ISO": "ER",
      "Currency_ISO": "ERI",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Ethiopia",
      "COUNTRY_CODE": "251",
      "Country_ISO": "ET",
      "Currency_ISO": "ETH",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Gabon",
      "COUNTRY_CODE": "241",
      "Country_ISO": "GA",
      "Currency_ISO": "GAB",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Gambia",
      "COUNTRY_CODE": "220",
      "Country_ISO": "GM",
      "Currency_ISO": "GMB",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Ghana",
      "COUNTRY_CODE": "233",
      "Country_ISO": "GH",
      "Currency_ISO": "GHA",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Guinea",
      "COUNTRY_CODE": "224",
      "Country_ISO": "GN",
      "Currency_ISO": "GIN",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Guinea-Bissau",
      "COUNTRY_CODE": "245",
      "Country_ISO": "GW",
      "Currency_ISO": "GNB",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Ivory Coast",
      "COUNTRY_CODE": "225",
      "Country_ISO": "CI",
      "Currency_ISO": "CIV",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Kenya",
      "COUNTRY_CODE": "254",
      "Country_ISO": "KE",
      "Currency_ISO": "KEN",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Lesotho",
      "COUNTRY_CODE": "266",
      "Country_ISO": "LS",
      "Currency_ISO": "LSO",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Liberia",
      "COUNTRY_CODE": "231",
      "Country_ISO": "LR",
      "Currency_ISO": "LBR",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Libya",
      "COUNTRY_CODE": "218",
      "Country_ISO": "LY",
      "Currency_ISO": "LBY",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Madagascar",
      "COUNTRY_CODE": "261",
      "Country_ISO": "MG",
      "Currency_ISO": "MDG",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Malawi",
      "COUNTRY_CODE": "265",
      "Country_ISO": "MW",
      "Currency_ISO": "MWI",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Mali",
      "COUNTRY_CODE": "223",
      "Country_ISO": "ML",
      "Currency_ISO": "MLI",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Mauritania",
      "COUNTRY_CODE": "222",
      "Country_ISO": "MR",
      "Currency_ISO": "MRT",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Mauritius",
      "COUNTRY_CODE": "230",
      "Country_ISO": "MU",
      "Currency_ISO": "MUS",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Mayotte",
      "COUNTRY_CODE": "262",
      "Country_ISO": "YT",
      "Currency_ISO": "MYT",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Morocco",
      "COUNTRY_CODE": "212",
      "Country_ISO": "MA",
      "Currency_ISO": "MAR",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Mozambique",
      "COUNTRY_CODE": "258",
      "Country_ISO": "MZ",
      "Currency_ISO": "MOZ",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Namibia",
      "COUNTRY_CODE": "264",
      "Country_ISO": "NA",
      "Currency_ISO": "NAM",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Niger",
      "COUNTRY_CODE": "227",
      "Country_ISO": "NE",
      "Currency_ISO": "NER",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Nigeria",
      "COUNTRY_CODE": "234",
      "Country_ISO": "NG",
      "Currency_ISO": "NGA",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Republic of the Congo",
      "COUNTRY_CODE": "242",
      "Country_ISO": "CG",
      "Currency_ISO": "COG",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Reunion",
      "COUNTRY_CODE": "262",
      "Country_ISO": "RE",
      "Currency_ISO": "REU",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Rwanda",
      "COUNTRY_CODE": "250",
      "Country_ISO": "RW",
      "Currency_ISO": "RWA",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Saint Helena",
      "COUNTRY_CODE": "290",
      "Country_ISO": "SH",
      "Currency_ISO": "SHN",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Sao Tome and Principe",
      "COUNTRY_CODE": "239",
      "Country_ISO": "ST",
      "Currency_ISO": "STP",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Senegal",
      "COUNTRY_CODE": "221",
      "Country_ISO": "SN",
      "Currency_ISO": "SEN",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Seychelles",
      "COUNTRY_CODE": "248",
      "Country_ISO": "SC",
      "Currency_ISO": "SYC",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Sierra Leone",
      "COUNTRY_CODE": "232",
      "Country_ISO": "SL",
      "Currency_ISO": "SLE",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Somalia",
      "COUNTRY_CODE": "252",
      "Country_ISO": "SO",
      "Currency_ISO": "SOM",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "South Africa",
      "COUNTRY_CODE": "27",
      "Country_ISO": "ZA",
      "Currency_ISO": "ZAF",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "South Sudan",
      "COUNTRY_CODE": "211",
      "Country_ISO": "SS",
      "Currency_ISO": "SSD",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Sudan",
      "COUNTRY_CODE": "249",
      "Country_ISO": "SD",
      "Currency_ISO": "SDN",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Swaziland",
      "COUNTRY_CODE": "268",
      "Country_ISO": "SZ",
      "Currency_ISO": "SWZ",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Tanzania",
      "COUNTRY_CODE": "255",
      "Country_ISO": "TZ",
      "Currency_ISO": "TZA",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Togo",
      "COUNTRY_CODE": "228",
      "Country_ISO": "TG",
      "Currency_ISO": "TGO",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Tunisia",
      "COUNTRY_CODE": "216",
      "Country_ISO": "TN",
      "Currency_ISO": "TUN",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Uganda",
      "COUNTRY_CODE": "256",
      "Country_ISO": "UG",
      "Currency_ISO": "UGA",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Western Sahara",
      "COUNTRY_CODE": "212",
      "Country_ISO": "EH",
      "Currency_ISO": "ESH",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Zambia",
      "COUNTRY_CODE": "260",
      "Country_ISO": "ZM",
      "Currency_ISO": "ZMB",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Zimbabwe",
      "COUNTRY_CODE": "263",
      "Country_ISO": "ZW",
      "Currency_ISO": "ZWE",
      "Continent": "Africa",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Antarctica",
      "COUNTRY_CODE": "672",
      "Country_ISO": "AQ",
      "Currency_ISO": "ATA",
      "Continent": "Antarctica",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Afghanistan",
      "COUNTRY_CODE": "93",
      "Country_ISO": "AF",
      "Currency_ISO": "AFG",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Armenia",
      "COUNTRY_CODE": "374",
      "Country_ISO": "AM",
      "Currency_ISO": "ARM",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Azerbaijan",
      "COUNTRY_CODE": "994",
      "Country_ISO": "AZ",
      "Currency_ISO": "AZE",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Bangladesh",
      "COUNTRY_CODE": "880",
      "Country_ISO": "BD",
      "Currency_ISO": "BGD",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Bhutan",
      "COUNTRY_CODE": "975",
      "Country_ISO": "BT",
      "Currency_ISO": "BTN",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Brunei",
      "COUNTRY_CODE": "673",
      "Country_ISO": "BN",
      "Currency_ISO": "BRN",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Cambodia",
      "COUNTRY_CODE": "855",
      "Country_ISO": "KH",
      "Currency_ISO": "KHM",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "China",
      "COUNTRY_CODE": "86",
      "Country_ISO": "CN",
      "Currency_ISO": "CHN",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Cyprus",
      "COUNTRY_CODE": "357",
      "Country_ISO": "CY",
      "Currency_ISO": "CYP",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "East Timor",
      "COUNTRY_CODE": "670",
      "Country_ISO": "TL",
      "Currency_ISO": "TLS",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Georgia",
      "COUNTRY_CODE": "995",
      "Country_ISO": "GE",
      "Currency_ISO": "GEO",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Hong Kong",
      "COUNTRY_CODE": "852",
      "Country_ISO": "HK",
      "Currency_ISO": "HKG",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "India",
      "COUNTRY_CODE": "91",
      "Country_ISO": "IN",
      "Currency_ISO": "IND",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Indonesia",
      "COUNTRY_CODE": "62",
      "Country_ISO": "ID",
      "Currency_ISO": "IDN",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Iran",
      "COUNTRY_CODE": "98",
      "Country_ISO": "IR",
      "Currency_ISO": "IRN",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Israel",
      "COUNTRY_CODE": "972",
      "Country_ISO": "IL",
      "Currency_ISO": "ISR",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Japan",
      "COUNTRY_CODE": "81",
      "Country_ISO": "JP",
      "Currency_ISO": "JPN",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Jordan",
      "COUNTRY_CODE": "962",
      "Country_ISO": "JO",
      "Currency_ISO": "JOR",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Kazakhstan",
      "COUNTRY_CODE": "7",
      "Country_ISO": "KZ",
      "Currency_ISO": "KAZ",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Kyrgyzstan",
      "COUNTRY_CODE": "996",
      "Country_ISO": "KG",
      "Currency_ISO": "KGZ",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Laos",
      "COUNTRY_CODE": "856",
      "Country_ISO": "LA",
      "Currency_ISO": "LAO",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Lebanon",
      "COUNTRY_CODE": "961",
      "Country_ISO": "LB",
      "Currency_ISO": "LBN",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Macau",
      "COUNTRY_CODE": "853",
      "Country_ISO": "MO",
      "Currency_ISO": "MAC",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Malaysia",
      "COUNTRY_CODE": "60",
      "Country_ISO": "MY",
      "Currency_ISO": "MYS",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Maldives",
      "COUNTRY_CODE": "960",
      "Country_ISO": "MV",
      "Currency_ISO": "MDV",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Mongolia",
      "COUNTRY_CODE": "976",
      "Country_ISO": "MN",
      "Currency_ISO": "MNG",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Myanmar",
      "COUNTRY_CODE": "95",
      "Country_ISO": "MM",
      "Currency_ISO": "MMR",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Nepal",
      "COUNTRY_CODE": "977",
      "Country_ISO": "NP",
      "Currency_ISO": "NPL",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "North Korea",
      "COUNTRY_CODE": "850",
      "Country_ISO": "KP",
      "Currency_ISO": "PRK",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Pakistan",
      "COUNTRY_CODE": "92",
      "Country_ISO": "PK",
      "Currency_ISO": "PAK",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Palestine",
      "COUNTRY_CODE": "970",
      "Country_ISO": "PS",
      "Currency_ISO": "PSE",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Philippines",
      "COUNTRY_CODE": "63",
      "Country_ISO": "PH",
      "Currency_ISO": "PHL",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Singapore",
      "COUNTRY_CODE": "65",
      "Country_ISO": "SG",
      "Currency_ISO": "SGP",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "South Korea",
      "COUNTRY_CODE": "82",
      "Country_ISO": "KR",
      "Currency_ISO": "KOR",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Sri Lanka",
      "COUNTRY_CODE": "94",
      "Country_ISO": "LK",
      "Currency_ISO": "LKA",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Syria",
      "COUNTRY_CODE": "963",
      "Country_ISO": "SY",
      "Currency_ISO": "SYR",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Tajikistan",
      "COUNTRY_CODE": "992",
      "Country_ISO": "TJ",
      "Currency_ISO": "TJK",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Thailand",
      "COUNTRY_CODE": "66",
      "Country_ISO": "TH",
      "Currency_ISO": "THA",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Turkey",
      "COUNTRY_CODE": "90",
      "Country_ISO": "TR",
      "Currency_ISO": "TUR",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Turkmenistan",
      "COUNTRY_CODE": "993",
      "Country_ISO": "TM",
      "Currency_ISO": "TKM",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Uzbekistan",
      "COUNTRY_CODE": "998",
      "Country_ISO": "UZ",
      "Currency_ISO": "UZB",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Vietnam",
      "COUNTRY_CODE": "84",
      "Country_ISO": "VN",
      "Currency_ISO": "VNM",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Yemen",
      "COUNTRY_CODE": "967",
      "Country_ISO": "YE",
      "Currency_ISO": "YEM",
      "Continent": "Asia",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Russia",
      "COUNTRY_CODE": "7",
      "Country_ISO": "RU",
      "Currency_ISO": "RUS",
      "Continent": "Europe",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Anguilla",
      "COUNTRY_CODE": "1-264",
      "Country_ISO": "AI",
      "Currency_ISO": "AIA",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Antigua and Barbuda",
      "COUNTRY_CODE": "1-268",
      "Country_ISO": "AG",
      "Currency_ISO": "ATG",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Aruba",
      "COUNTRY_CODE": "297",
      "Country_ISO": "AW",
      "Currency_ISO": "ABW",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Bahamas",
      "COUNTRY_CODE": "1-242",
      "Country_ISO": "BS",
      "Currency_ISO": "BHS",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Barbados",
      "COUNTRY_CODE": "1-246",
      "Country_ISO": "BB",
      "Currency_ISO": "BRB",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Belize",
      "COUNTRY_CODE": "501",
      "Country_ISO": "BZ",
      "Currency_ISO": "BLZ",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Bermuda",
      "COUNTRY_CODE": "1-441",
      "Country_ISO": "BM",
      "Currency_ISO": "BMU",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "British Virgin Islands",
      "COUNTRY_CODE": "1-284",
      "Country_ISO": "VG",
      "Currency_ISO": "VGB",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Canada",
      "COUNTRY_CODE": "1",
      "Country_ISO": "CA",
      "Currency_ISO": "CAN",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Cayman Islands",
      "COUNTRY_CODE": "1-345",
      "Country_ISO": "KY",
      "Currency_ISO": "CYM",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Costa Rica",
      "COUNTRY_CODE": "506",
      "Country_ISO": "CR",
      "Currency_ISO": "CRI",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Cuba",
      "COUNTRY_CODE": "53",
      "Country_ISO": "CU",
      "Currency_ISO": "CUB",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Curacao",
      "COUNTRY_CODE": "599",
      "Country_ISO": "CW",
      "Currency_ISO": "CUW",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Dominica",
      "COUNTRY_CODE": "1-767",
      "Country_ISO": "DM",
      "Currency_ISO": "DMA",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Dominican Republic",
      "COUNTRY_CODE": "1-809, 1-829, 1-849",
      "Country_ISO": "DO",
      "Currency_ISO": "DOM",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "El Salvador",
      "COUNTRY_CODE": "503",
      "Country_ISO": "SV",
      "Currency_ISO": "SLV",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Greenland",
      "COUNTRY_CODE": "299",
      "Country_ISO": "GL",
      "Currency_ISO": "GRL",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Grenada",
      "COUNTRY_CODE": "1-473",
      "Country_ISO": "GD",
      "Currency_ISO": "GRD",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Guatemala",
      "COUNTRY_CODE": "502",
      "Country_ISO": "GT",
      "Currency_ISO": "GTM",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Haiti",
      "COUNTRY_CODE": "509",
      "Country_ISO": "HT",
      "Currency_ISO": "HTI",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Honduras",
      "COUNTRY_CODE": "504",
      "Country_ISO": "HN",
      "Currency_ISO": "HND",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Jamaica",
      "COUNTRY_CODE": "1-876",
      "Country_ISO": "JM",
      "Currency_ISO": "JAM",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Mexico",
      "COUNTRY_CODE": "52",
      "Country_ISO": "MX",
      "Currency_ISO": "MEX",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Montserrat",
      "COUNTRY_CODE": "1-664",
      "Country_ISO": "MS",
      "Currency_ISO": "MSR",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Nicaragua",
      "COUNTRY_CODE": "505",
      "Country_ISO": "NI",
      "Currency_ISO": "NIC",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Panama",
      "COUNTRY_CODE": "507",
      "Country_ISO": "PA",
      "Currency_ISO": "PAN",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Puerto Rico",
      "COUNTRY_CODE": "1-787, 1-939",
      "Country_ISO": "PR",
      "Currency_ISO": "PRI",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Saint Barthelemy",
      "COUNTRY_CODE": "590",
      "Country_ISO": "BL",
      "Currency_ISO": "BLM",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Saint Kitts and Nevis",
      "COUNTRY_CODE": "1-869",
      "Country_ISO": "KN",
      "Currency_ISO": "KNA",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Saint Lucia",
      "COUNTRY_CODE": "1-758",
      "Country_ISO": "LC",
      "Currency_ISO": "LCA",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Saint Martin",
      "COUNTRY_CODE": "590",
      "Country_ISO": "MF",
      "Currency_ISO": "MAF",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Saint Pierre and Miquelon",
      "COUNTRY_CODE": "508",
      "Country_ISO": "PM",
      "Currency_ISO": "SPM",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Saint Vincent and the Grenadines",
      "COUNTRY_CODE": "1-784",
      "Country_ISO": "VC",
      "Currency_ISO": "VCT",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Sint Maarten",
      "COUNTRY_CODE": "1-721",
      "Country_ISO": "SX",
      "Currency_ISO": "SXM",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Trinidad and Tobago",
      "COUNTRY_CODE": "1-868",
      "Country_ISO": "TT",
      "Currency_ISO": "TTO",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Turks and Caicos Islands",
      "COUNTRY_CODE": "1-649",
      "Country_ISO": "TC",
      "Currency_ISO": "TCA",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "U.S. Virgin Islands",
      "COUNTRY_CODE": "1-340",
      "Country_ISO": "VI",
      "Currency_ISO": "VIR",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "United States",
      "COUNTRY_CODE": "1",
      "Country_ISO": "US",
      "Currency_ISO": "USA",
      "Continent": "North America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "American Samoa",
      "COUNTRY_CODE": "1-684",
      "Country_ISO": "AS",
      "Currency_ISO": "ASM",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Australia",
      "COUNTRY_CODE": "61",
      "Country_ISO": "AU",
      "Currency_ISO": "AUS",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Christmas Island",
      "COUNTRY_CODE": "61",
      "Country_ISO": "CX",
      "Currency_ISO": "CXR",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Cocos Islands",
      "COUNTRY_CODE": "61",
      "Country_ISO": "CC",
      "Currency_ISO": "CCK",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Cook Islands",
      "COUNTRY_CODE": "682",
      "Country_ISO": "CK",
      "Currency_ISO": "COK",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Fiji",
      "COUNTRY_CODE": "679",
      "Country_ISO": "FJ",
      "Currency_ISO": "FJI",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "French Polynesia",
      "COUNTRY_CODE": "689",
      "Country_ISO": "PF",
      "Currency_ISO": "PYF",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Guam",
      "COUNTRY_CODE": "1-671",
      "Country_ISO": "GU",
      "Currency_ISO": "GUM",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Kiribati",
      "COUNTRY_CODE": "686",
      "Country_ISO": "KI",
      "Currency_ISO": "KIR",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Marshall Islands",
      "COUNTRY_CODE": "692",
      "Country_ISO": "MH",
      "Currency_ISO": "MHL",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Micronesia",
      "COUNTRY_CODE": "691",
      "Country_ISO": "FM",
      "Currency_ISO": "FSM",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Nauru",
      "COUNTRY_CODE": "674",
      "Country_ISO": "NR",
      "Currency_ISO": "NRU",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "New Caledonia",
      "COUNTRY_CODE": "687",
      "Country_ISO": "NC",
      "Currency_ISO": "NCL",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "New Zealand",
      "COUNTRY_CODE": "64",
      "Country_ISO": "NZ",
      "Currency_ISO": "NZL",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Niue",
      "COUNTRY_CODE": "683",
      "Country_ISO": "NU",
      "Currency_ISO": "NIU",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Northern Mariana Islands",
      "COUNTRY_CODE": "1-670",
      "Country_ISO": "MP",
      "Currency_ISO": "MNP",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Palau",
      "COUNTRY_CODE": "680",
      "Country_ISO": "PW",
      "Currency_ISO": "PLW",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Papua New Guinea",
      "COUNTRY_CODE": "675",
      "Country_ISO": "PG",
      "Currency_ISO": "PNG",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Pitcairn",
      "COUNTRY_CODE": "64",
      "Country_ISO": "PN",
      "Currency_ISO": "PCN",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Samoa",
      "COUNTRY_CODE": "685",
      "Country_ISO": "WS",
      "Currency_ISO": "WSM",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Solomon Islands",
      "COUNTRY_CODE": "677",
      "Country_ISO": "SB",
      "Currency_ISO": "SLB",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Tokelau",
      "COUNTRY_CODE": "690",
      "Country_ISO": "TK",
      "Currency_ISO": "TKL",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Tonga",
      "COUNTRY_CODE": "676",
      "Country_ISO": "TO",
      "Currency_ISO": "TON",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Tuvalu",
      "COUNTRY_CODE": "688",
      "Country_ISO": "TV",
      "Currency_ISO": "TUV",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Vanuatu",
      "COUNTRY_CODE": "678",
      "Country_ISO": "VU",
      "Currency_ISO": "VUT",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Wallis and Futuna",
      "COUNTRY_CODE": "681",
      "Country_ISO": "WF",
      "Currency_ISO": "WLF",
      "Continent": "Oceania",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Argentina",
      "COUNTRY_CODE": "54",
      "Country_ISO": "AR",
      "Currency_ISO": "ARG",
      "Continent": "South America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Bolivia",
      "COUNTRY_CODE": "591",
      "Country_ISO": "BO",
      "Currency_ISO": "BOL",
      "Continent": "South America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Brazil",
      "COUNTRY_CODE": "55",
      "Country_ISO": "BR",
      "Currency_ISO": "BRA",
      "Continent": "South America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Chile",
      "COUNTRY_CODE": "56",
      "Country_ISO": "CL",
      "Currency_ISO": "CHL",
      "Continent": "South America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Colombia",
      "COUNTRY_CODE": "57",
      "Country_ISO": "CO",
      "Currency_ISO": "COL",
      "Continent": "South America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Ecuador",
      "COUNTRY_CODE": "593",
      "Country_ISO": "EC",
      "Currency_ISO": "ECU",
      "Continent": "South America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Falkland Islands",
      "COUNTRY_CODE": "500",
      "Country_ISO": "FK",
      "Currency_ISO": "FLK",
      "Continent": "South America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Guyana",
      "COUNTRY_CODE": "592",
      "Country_ISO": "GY",
      "Currency_ISO": "GUY",
      "Continent": "South America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Paraguay",
      "COUNTRY_CODE": "595",
      "Country_ISO": "PY",
      "Currency_ISO": "PRY",
      "Continent": "South America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Peru",
      "COUNTRY_CODE": "51",
      "Country_ISO": "PE",
      "Currency_ISO": "PER",
      "Continent": "South America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Suriname",
      "COUNTRY_CODE": "597",
      "Country_ISO": "SR",
      "Currency_ISO": "SUR",
      "Continent": "South America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Uruguay",
      "COUNTRY_CODE": "598",
      "Country_ISO": "UY",
      "Currency_ISO": "URY",
      "Continent": "South America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Venezuela",
      "COUNTRY_CODE": "58",
      "Country_ISO": "VE",
      "Currency_ISO": "VEN",
      "Continent": "South America",
      "Currency": "USD"
  },
  {
      "COUNTRY": "Taiwan",
      "COUNTRY_CODE": "886",
      "Country_ISO": "TW",
      "Currency_ISO": "TWN",
      "Continent": "Asia",
      "Currency": "USD"
  }
]
const useInitUTM = () => {
  const {
    gclid,
    fbclid,
    leadSource,
    ipAddress,
    cityName,
    stateName,
    countryFullName,
    utmMedium,
    utmCampaignId,
    utmTerm,
    setIpAddress,
    setLeadSource,
    setUtmKeyword,
    setUtmPlatform,
    setGclid,
    setFbclid,
    setUtmMedium,
    setUtmCampaignId,
    setUtmTerm,
    countryName,
    setCountry,
    setCurrencyType,
    setCityName,
    setStateName,
    setStateCountryFullName,
    setCountryCode
  } = useGlobalStore()

  const [gclidData] = useQueryParam("gclid", "")
  const [fbclidData] = useQueryParam("fbclid", "")
  const [utmMediumData] = useQueryParam("utm_medium", "")
  const [utmCampaignIdData] = useQueryParam("utm_campaign", "")
  const [utmTermData] = useQueryParam("utm_term", "")
  const [leadSourceData] = useQueryParam("utm_source", "")
  const [gad_source] = useQueryParam("gad_source", "")
  const [keyword] = useQueryParam("utm_keyword", "")
  const [platform] = useQueryParam("utm_platform", "")
  if (gclidData && gclidData !== gclid) {
    setGclid(gclidData)
    setLeadSource("3369")
  }

  if (fbclidData && fbclidData !== fbclid) {
    setFbclid(fbclidData)
  }

  if (utmMediumData && utmMediumData !== utmMedium) {
    setUtmMedium(utmMediumData)
  }

  if (utmCampaignIdData && utmCampaignIdData !== utmCampaignId) {
    setUtmCampaignId(utmCampaignIdData)
  }

  if (utmTermData && utmTermData !== utmTerm) {
    setUtmTerm(utmTermData)
  }

  if (leadSourceData && leadSourceData !== leadSource) {
    setLeadSource(leadSourceData)
  }
  if(gad_source){
    setLeadSource("3369")
  }
  if(keyword) {
    setUtmKeyword(keyword)
  }
  if(platform){
    setUtmPlatform(platform)
  }

  const { ipAddress: apiIP, country, countryCode, city, region, countryLabel } = useIP()

  useEffect(() => {
    setCityName(city)
    setStateName(region)
    setStateCountryFullName(countryLabel)
    if (apiIP && apiIP !== ipAddress) setIpAddress(apiIP)
    setCountry(countryLabel)
    setCountryCode(countryCode)
    const obj = countryList?.find((item) => item.Country_ISO === countryCode?.toUpperCase())
    const obj1 = currencyTypeOptionsSymbols?.find((item) => item.value === obj?.Currency)
    setCurrencyType((typeof window !== "undefined" &&
    localStorage.getItem("currency") === "object" &&
    localStorage.getItem("currency") != null) ||
  (typeof window !== "undefined" && !!localStorage.getItem("currency"))
    ? JSON.parse(localStorage.getItem("currency"))
    : obj?.Currency && obj1?.label ?  { value: obj?.Currency, label: obj1?.label } : { value: "AED", label: "UAE Dirams - AED د.إ" })
  }, [apiIP, country, countryLabel, countryCode, city, region])

  return {
    gclid,
    fbclid,
    leadSource,
    ipAddress,
    utmMedium,
    utmCampaignId,
    utmTerm,
  }
}

export default useInitUTM
