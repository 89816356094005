import BrochureModalWithTrigger from "@components/BrochureModal/BrochureModal"
import RegisterInterestModalWithTrigger from "@components/RegisterInterestModal/RegisterInterestModal"
import GGFXImage from "@components/elements/GGFXImage"
import React from "react"
import { Container } from "react-bootstrap"
import "./BannerOffplan.scss"
import { slugToTitle, slugifynew } from "@lib/utils"
import { Link } from "gatsby"
import { offplanResultsSaleURL } from "@lib/urls"
// import useDeviceMedia from "@hooks/useDeviceMedia"
import { get, isEmpty } from "lodash"
import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs"
import BannerMenu from "@components/Banner/BannerMenu"

const BannerOffplan = ({ offplan }) => {
  // const { isMobile } = useDeviceMedia()

  const {
    title,
    developer,
    banner_image,
    banner_image_mobile,
    ggfx_results,
    strapi_id,
    images,
    country,
    community,
  } = offplan

  const developerSlug = slugifynew(developer)
  const DevComp = developerSlug ? Link : "p"

  const hasMobileBanner = !isEmpty(banner_image_mobile)

  const mobileBanner = hasMobileBanner ? banner_image_mobile : banner_image
  // const mobileTransforms =
  //   (hasMobileBanner
  //     ? imagetransforms?.banner_image_mobile_Transforms
  //     : imagetransforms?.banner_image_Transforms) || JSON.stringify({})

  const breadcrumbURLs = [
    // {
    //   url: `${offplanResultsSaleURL}in-dubai/`,
    //   label: "Find a Project",
    // },
    {
      url: `${offplanResultsSaleURL}in-${country?.toLowerCase()?.replace(/ /g, "-")}/`,
      label: `All Projects in ${country}`,
    },
    {
      url: `${offplanResultsSaleURL}in-${community?.title?.toLowerCase()?.replace(/ /g, "-")?.replace(/[^-a-zA-Z0-9 ]/g, "")}/`,
      label: community?.title,
    },
    {
      url: "",
      label: title,
    },
  ]

  return (
    <div className="offplan-banner-wrap">
      {banner_image === null ?
        <div className="bg-section bg">
          <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={images[0]?.url ? images[0]?.url : images[0]?.srcUrl} alt={`${title}`} />
          <div className="overlay" />
        </div> : <>
          <div className="bg-section d-block d-md-none">
            {get(offplan?.algoliaData, "banner_image_mobile.0._744x") ?
              <img loading="eager"
                src={get(offplan?.algoliaData, "banner_image_mobile.0._744x")?.replace(process.env.GATSBY_GGFX_S3_HOST, process.env.GATSBY_GGFX_CDN_HOST)} alt={`${title} - ${process.env.GATSBY_SITE_NAME}`} />
              :
              <GGFXImage
                ImageSrc={mobileBanner}
                altText={title}
                imagetransforms={ggfx_results}
                renderer="srcSet"
                imagename={
                  hasMobileBanner
                    ? "off-plan.banner_image_mobile.main_img"
                    : "off-plan.banner_image.main_img"
                }
                strapiID={strapi_id}
              />}
            <div className="overlay" />
          </div>

          {get(offplan?.algoliaData, "banner_image.0._1650x") &&
            <div className="bg-section d-none d-lg-block">
              <img loading="eager"
                src={get(offplan?.algoliaData, "banner_image.0._1650x")?.replace(process.env.GATSBY_GGFX_S3_HOST, process.env.GATSBY_GGFX_CDN_HOST)} alt={`${title} - ${process.env.GATSBY_SITE_NAME}`} />
              <div className="overlay" />
            </div>
}
            {get(offplan?.algoliaData, "banner_image.0._744x") &&
            <div className="bg-section d-none d-md-block d-lg-none">
              <img loading="eager"
                src={get(offplan?.algoliaData, "banner_image.0._744x")?.replace(process.env.GATSBY_GGFX_S3_HOST, process.env.GATSBY_GGFX_CDN_HOST)} alt={`${title} - ${process.env.GATSBY_SITE_NAME}`} />
              <div className="overlay" />
            </div> 
            }
            {get(offplan?.algoliaData, "banner_image.0._376x") &&
            <div className="bg-section d-block d-md-none">
              <img loading="eager"
                src={get(offplan?.algoliaData, "banner_image.0._376x")?.replace(process.env.GATSBY_GGFX_S3_HOST, process.env.GATSBY_GGFX_CDN_HOST)} alt={`${title} - ${process.env.GATSBY_SITE_NAME}`} />
              <div className="overlay" />
            </div>}
              {!get(offplan?.algoliaData, "banner_image.0._1650x") && !get(offplan?.algoliaData, "banner_image.0._744x") && !get(offplan?.algoliaData, "banner_image.0._376x") &&
            <div className="bg-section d-none d-lg-block">
              <GGFXImage
                ImageSrc={banner_image}
                altText={title}
                imagetransforms={
                  ggfx_results
                }
                renderer="srcSet"
                imagename="off-plan.banner_image.main_img"
                strapiID={strapi_id}
              />
              <div className="overlay" />
            </div>
}


          {/* {get(offplan?.algoliaData, "banner_image.0._1650x") ?
            <div className="bg-section d-none d-md-block">
              <img loading="eager"
                src={get(offplan?.algoliaData, "banner_image.0._1650x")?.replace(process.env.GATSBY_GGFX_S3_HOST, process.env.GATSBY_GGFX_CDN_HOST)} alt={`${title} - ${process.env.GATSBY_SITE_NAME}`} />
              <div className="overlay" />
            </div>
            :
            <div className="bg-section d-none d-lg-block">
              <GGFXImage
                ImageSrc={banner_image}
                altText={title}
                imagetransforms={
                  ggfx_results
                }
                renderer="srcSet"
                imagename="off-plan.banner_image.main_img"
                strapiID={strapi_id}
              />
              <div className="overlay" />
            </div>
          } */}
                    {/* {get(offplan?.algoliaData, "banner_image.0._744x") ?
            <div className="bg-section d-none d-md-block d-lg-none">
              <img loading="eager"
                src={get(offplan?.algoliaData, "banner_image.0._744x")?.replace(process.env.GATSBY_GGFX_S3_HOST, process.env.GATSBY_GGFX_CDN_HOST)} alt={`${title} - ${process.env.GATSBY_SITE_NAME}`} />
              <div className="overlay" />
            </div>
            :
            <div className="bg-section d-none d-md-block">
              <GGFXImage
                ImageSrc={banner_image}
                altText={title}
                imagetransforms={
                  ggfx_results
                }
                renderer="srcSet"
                imagename="off-plan.banner_image.main_img"
                strapiID={strapi_id}
              />
              <div className="overlay" />
            </div>
          }

{get(offplan?.algoliaData, "banner_image.0._376x") ?
            <div className="bg-section d-block d-md-none">
              <img loading="eager"
                src={get(offplan?.algoliaData, "banner_image.0._376x")?.replace(process.env.GATSBY_GGFX_S3_HOST, process.env.GATSBY_GGFX_CDN_HOST)} alt={`${title} - ${process.env.GATSBY_SITE_NAME}`} />
              <div className="overlay" />
            </div>
            :
            <div className="bg-section d-none d-md-block">
              <GGFXImage
                ImageSrc={banner_image}
                altText={title}
                imagetransforms={
                  ggfx_results
                }
                renderer="srcSet"
                imagename="off-plan.banner_image.main_img"
                strapiID={strapi_id}
              />
              <div className="overlay" />
            </div>
          } */}




        </>
      }
      <BannerMenu />

      <Container className="offplan-banner-container">
        <div className="offplan-banner-section">
          <div className="content-section">
            <h1>
              <p className="title">{title}</p>{" "}
              {developer && (
                <DevComp
                  to={`${offplanResultsSaleURL}developed-by-${developerSlug}/`}
                  className="developer"
                >
                  by <span>{slugToTitle(developer)}</span>
                </DevComp>
              )}
            </h1>
            <div className="cta-section">
              <BrochureModalWithTrigger offplan={offplan} />
              <RegisterInterestModalWithTrigger offplan={offplan} />
            </div>
            <Breadcrumbs staticURLs={breadcrumbURLs} whiteColor />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default BannerOffplan
