import ReadMore from "@components/ReadMore/ReadMore"
import TeamReviewsSlider from "@components/TeamReviewsSlider/TeamReviewsSlider"
import GGFXImage from "@components/elements/GGFXImage"
import PhoneIcon from "@components/icons/PhoneIcon"
import WhatsAppIcon from "@components/icons/WhatsAppIcon"
// import useDeviceMedia from "@hooks/useDeviceMedia"
import { addRollWhatsapp, getWhatsAppURL, gtagReportConversion } from "@lib/utils"
import React from "react"
import "./TeamInfo.scss"
import { teamsURL } from "@lib/urls"
const TeamInfo = ({ team }) => {
  // const { isTablet } = useDeviceMedia()

  const {
    category,
    services,
    locations,
    name,
    about,
    designation,
    phone,
    office_phone,
    languages,
    image,
    experience_since,
    reviews,
    ggfx_results,
    strapi_id,
    email,
    extra,
    negotiator_mapping,
    slug,
    community,
    developers
  } = team

 
  const isManagement = category?.strapi_json_value?.includes("Management")
  const calculateExperience = (experience_since) => {
    if (!experience_since) {
      // Handle missing or undefined value
      return 1; // Default to 0 years
    }
  
    const startDate = new Date(experience_since);
  
    // Check if the date is invalid
    if (isNaN(startDate.getTime())) {
      return 0; // Default to 0 years for invalid dates
    }
    const currentDate = new Date();
    const yearDifference = currentDate.getFullYear() - startDate.getFullYear();
  
    // Adjust for months and days
    const hasCompletedYear =
      currentDate.getMonth() > startDate.getMonth() ||
      (currentDate.getMonth() === startDate.getMonth() &&
        currentDate.getDate() >= startDate.getDate());
  
    // If the current date hasn't reached the anniversary, subtract one year
    const totalYears = hasCompletedYear ? yearDifference : yearDifference - 1;
  
    // Ensure minimum 1 year is shown
    return totalYears < 1 ? 1 : totalYears;
  };

const yearsOfExperience = calculateExperience(experience_since);

  return (
    <div className="team-info-section">
      <div className="left-section">
        <h1 className="name">{name}</h1>
        <p className="designation">{designation}</p>
        {!isManagement && (
          <div className="cta-section">
            {phone &&
            <a href={`tel:${phone}`} onClick={() => gtagReportConversion(`tel:${phone}`)} className="button button-white">
              <PhoneIcon />
              <span>Call</span>
            </a>}

            {phone &&<a target="_blank" 
            href={getWhatsAppURL(
              "971505389860",
              `Hello Provident,\n\nI would like to contact ${name}\n\n• Email: ${email}\n• Link: ${process.env.GATSBY_SITE_URL + teamsURL+slug}\n• ID: ${negotiator_mapping}\n\nModifying this message will prevent it from being sent to the agent.`,
              email,
              phone,
              name
            )} 
            className="button button-green" onClick={()=> addRollWhatsapp()}>
              <WhatsAppIcon outline2 />
              <span>WhatsApp</span>
            </a>}
          </div>
        )}
        {/* <p className="heading">key details</p> */}
        {!isManagement && (
        <div className="key-details-section">
          <div className="key-detail">
            <p className="label">Experience</p>
            <p className="value">{yearsOfExperience} {yearsOfExperience === 1 ? 'year' : 'years'}</p>
          </div>
          <div className="key-detail">
            <p className="label">Languages</p>
            <p className="value">
              {languages?.strapi_json_value?.length
                ? languages.strapi_json_value.join(", ")
                : "N/A"}
            </p>
          </div>
          {/* <div className="key-detail">
            <p className="label">Services</p>
            <p className="value">{services || "N/A"}</p>
          </div> */}
          <div className="key-detail">
            <p className="label">Areas of Expertise</p>
            <p className="value">{community?.strapi_json_value?.length
                ? community.strapi_json_value.join(", ")
                : "N/A"}</p>
          </div>
          {developers?.length > 0 &&
          <div className="key-detail">
            <p className="label">Developers of Expertise</p>
            <p className="value">{developers?.length
    ? developers.map((developer) => developer.name).join(", ")
    : "N/A"}</p>
          </div>}
        </div>)}
        {about?.data?.about && (
          <div className="about-section-wrap" id="about-section">
            <p className="heading">About {name}</p>
            <ReadMore
              content={about?.data?.about}
              className="about-section"
              scrollToId="about-section"
            />
          </div>
        )}
        <TeamReviewsSlider reviews={reviews} />
      </div>
      <div className="right-section">
        <div className="image-wrap">
        {image?.url ? 
          <GGFXImage
            ImageSrc={image}
            altText={name}
            imagetransforms={ggfx_results}
            renderer="srcSet"
            imagename="team.image.detail_img"
            strapiID={strapi_id}
          /> : extra?.profile_image ?
          <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={extra?.profile_image} alt={`${name} - ${process.env.GATSBY_SITE_NAME}`} />
          : <GGFXImage
          ImageSrc={image}
          altText={name}
          imagetransforms={ggfx_results}
          renderer="srcSet"
          imagename="team.image.detail_img"
          strapiID={strapi_id}
        />}
        </div>
      </div>
    </div>
  )
}

export default TeamInfo
