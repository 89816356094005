import BannerMenu from "@components/Banner/BannerMenu"
import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs"
import CareerInfo from "@components/CareerInfo/CareerInfo"
import FloatingCTAShell from "@components/FloatingCTAShell/FloatingCTAShell"
import Layout from "@components/Layout/Layout"
import MailIcon from "@components/icons/MailIcon"
import MobileIcon from "@components/icons/MobileIcon"
import WhatsAppIcon from "@components/icons/WhatsAppIcon"
import Seo from "@components/seo"
import { aboutURL, careersURL, sendCVURL } from "@lib/urls"
import { addRollWhatsapp, getWhatsAppURL, getWhatsBaseURL, gtagReportConversion } from "@lib/utils"
import { Link, graphql, navigate } from "gatsby"
import React, { useEffect } from "react"

const CareerDetailTemplate = ({ data }) => {
  const career = data?.strapiCareer

  // if (!career) return navigate("/404/")

  const { title, team } = career

  const breadcrumbURLs = [
    {
      url: aboutURL,
      label: "About",
    },
    {
      url: careersURL,
      label: "Careers",
    },
    {
      url: "",
      label: title,
    },
  ]
  useEffect(() => {
    if (sessionStorage.getItem("searchpath") && typeof window !== "undefined") {
      sessionStorage.removeItem("searchpath");
    }
    if(typeof window !== "undefined"){
      localStorage.setItem('careerUrl', typeof window !== "undefined" && window.location.href)
      localStorage.setItem('careerPosition', title)
    }
  }, []);
  const pageurl = typeof window !== "undefined" && window.location.href 
  
  return (
    <>
      <Layout
        pageData={{
          page_class: "career-detail-page",
          layout: "career-detail",
        }}
        hideNewsletter
      >
        <BannerMenu color="black" />
        <Breadcrumbs staticURLs={breadcrumbURLs} />
        <CareerInfo career={career} team={team} pageurl={pageurl} />
      </Layout>
      <FloatingCTAShell>
        <Link
          to={sendCVURL}
          className="button button-orange"
          state={{
            jobPosition: title,
            pageurl: pageurl
          }}
        >
          <span>Apply</span>
          <MailIcon color="white" />
        </Link>
        {team && (
          <>
            <a
              href={`tel:${team.phone}`}
              className="button button-gray"
              onClick={() => gtagReportConversion(`tel:${team.phone}`)}
            >
              <span>Call</span>
              <MobileIcon />
            </a>
            <a
              href={getWhatsBaseURL(team?.phone)}
              className="button button-gray"
              target="_blank"
              rel="noreferrer"
              onClick={()=> addRollWhatsapp()}
              >
              <span>Whatsapp</span>
              <WhatsAppIcon />
            </a>
          </>
        )}
      </FloatingCTAShell>
    </>
  )
}

export default CareerDetailTemplate

export const Head = ({ data }) => {
  const pageData = data?.strapiCareer

  return (
    <Seo
      title={`Real Estate Job in Dubai | ${pageData?.title}`}
      description={`Explore a rewarding career opportunity as an ${pageData?.title} at Provident Estate. Discover the qualifications, responsibilities, and benefits of the role, and apply today to be a part of our growth journey.`}
      customCanonical={pageData?.seo?.canonicalURL}
    />
  )
}

export const query = graphql`
  query ($id: String) {
    strapiCareer(id: { eq: $id }) {
      ...careerFragment
    }
  }
`
